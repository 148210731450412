import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <a id="epic-prestige-classes"></a><h2>Epic Prestige Classes
    </h2>
    <a id="perfect-wight"></a><h3>PERFECT WIGHT</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td className="line"> </td>
        </tr>
      </tbody>
    </table>
    <span style={{
      "fontWeight": "bold"
    }}>Hit Die:</span> d6.
    <a id="perfect-wight-requirements"></a><h6>Requirements</h6>
To qualify to become a perfect wight, a character must fulfill
all the following criteria.
    <p><span style={{
        "fontWeight": "bold"
      }}>Skills:</span> <a href="skillsAll.html#hide" style={{
        "color": "rgb(87, 158, 182)"
      }}>Hide</a>
24 ranks, <a href="skillsAll.html#move-silently" style={{
        "color": "rgb(87, 158, 182)"
      }}>Move Silently</a> 24 ranks. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Epic Feats:</span> <a href="epicFeats.html#self-concealment" style={{
        "color": "rgb(87, 158, 182)"
      }}>Self-Concealment</a>. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Special:</span> Sneak attack
+10d6. </p>
    <p style={{
      "fontWeight": "bold"
    }}>Class Skills</p>
    <p>The perfect wight&#8217;s class skills (and the key ability for each
skill) are <a href="skillsAll.html#appraise" style={{
        "color": "rgb(87, 158, 182)"
      }}>Appraise</a> (Int), <a href="skillsAll.html#balance" style={{
        "color": "rgb(87, 158, 182)"
      }}>Balance</a>
(Dex), <a href="skillsAll.html#bluff" style={{
        "color": "rgb(87, 158, 182)"
      }}>Bluff</a>
(Cha), <a href="skillsAll.html#climb" style={{
        "color": "rgb(87, 158, 182)"
      }}>Climb</a>
(Str), <a href="skillsAll.html#craft" style={{
        "color": "rgb(87, 158, 182)"
      }}>Craft</a>
(Int), <a href="skillsAll.html#disable-device" style={{
        "color": "rgb(87, 158, 182)"
      }}>Disable Device </a>(Int), <a href="skillsAll.html#diplomacy" style={{
        "color": "rgb(87, 158, 182)"
      }}>Diplomacy</a>
(Cha), <a href="skillsAll.html#disguise" style={{
        "color": "rgb(87, 158, 182)"
      }}>Disguise</a> (Cha), <a href="skillsAll.html#escape-artist" style={{
        "color": "rgb(87, 158, 182)"
      }}>Escape
Artist</a> (Dex), <a href="skillsAll.html#gather-information" style={{
        "color": "rgb(87, 158, 182)"
      }}>Gather Information</a> (Cha), <a href="skillsAll.html#hide" style={{
        "color": "rgb(87, 158, 182)"
      }}>Hide</a>
(Dex), <a href="skillsAll.html#innuendo" style={{
        "color": "rgb(87, 158, 182)"
      }}>Innuendo</a> (Wis), <a href="skillsAll.html#jump" style={{
        "color": "rgb(87, 158, 182)"
      }}>Jump</a>
(Str), <a href="skillsAll.html#knowledge" style={{
        "color": "rgb(87, 158, 182)"
      }}>Knowledge</a> (any) (Int), <a href="skillsAll.html#listen" style={{
        "color": "rgb(87, 158, 182)"
      }}>Listen</a>
(Wis), <a href="skillsAll.html#move-silently" style={{
        "color": "rgb(87, 158, 182)"
      }}>Move Silently</a> (Dex), <a href="skillsAll.html#open-lock" style={{
        "color": "rgb(87, 158, 182)"
      }}>Open
Lock</a> (Dex), <a href="skillsAll.html#search" style={{
        "color": "rgb(87, 158, 182)"
      }}>Search</a> (Int), <a href="skillsAll.html#spot" style={{
        "color": "rgb(87, 158, 182)"
      }}>Spot</a>
(Wis), <a href="skillsAll.html#survival" style={{
        "color": "rgb(87, 158, 182)"
      }}>Survival</a> (Wis), <a href="skillsAll.html#tumble" style={{
        "color": "rgb(87, 158, 182)"
      }}>Tumble</a>
(Dex), and <a href="skillsAll.html#use-rope" style={{
        "color": "rgb(87, 158, 182)"
      }}>Use Rope</a> (Dex). </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Skill Points at Each Level:</span>
8 + Int modifier.</p>
    <a id="table-the-perfect-wight"></a><span style={{
      "fontWeight": "bold"
    }}>
Table: The Perfect Wight
    </span>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "100px"
          }}>Level</th>
          <th style={{
            "width": "350px"
          }}>Special
          </th>
        </tr>
        <tr className="odd-row">
          <td>1st
          </td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Greater
invisibility 1/day
          </td>
        </tr>
        <tr>
          <td>2nd
          </td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Improved
legerdemain 1/day</td>
        </tr>
        <tr className="odd-row">
          <td>3rd
          </td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Incorporeal
1/day</td>
        </tr>
        <tr>
          <td>4th
          </td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Shadow
form 1/day</td>
        </tr>
        <tr className="odd-row">
          <td>5th
          </td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Bonus feat</td>
        </tr>
        <tr>
          <td>6th
          </td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Greater
invisibility 2/day</td>
        </tr>
        <tr className="odd-row">
          <td>7th
          </td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Improved
legerdemain 2/day</td>
        </tr>
        <tr>
          <td>8th
          </td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Incorporeal
2/day</td>
        </tr>
        <tr className="odd-row">
          <td>9th
          </td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Shadow
form 2/day</td>
        </tr>
        <tr>
          <td className="last-row">10th
          </td>
          <td className="last-row" rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Bonus feat</td>
        </tr>
      </tbody>
    </table>
    <a id="perfect-wight-class-features"></a><h6>Class Features</h6>
The following are class features of the perfect wight prestige class. 
    <p><span style={{
        "fontWeight": "bold"
      }}>Weapon and Armor Proficiency:</span>
A perfect wight gains no proficiency with any weapons, armor, or
shields. </p>
    <a id="perfect-wight-greater-invisibility"></a><p><span style={{
        "fontWeight": "bold"
      }}>Greater Invisibility (Su):</span>
Starting at 1st level, the perfect wight gains the benefit of greater
invisibility once per day, plus one additional time per day every five
levels thereafter. The greater invisibility is as the spell cast by a
20th-level caster. </p>
    <a id="perfect-wight-improved-legerdemain"></a><p><span style={{
        "fontWeight": "bold"
      }}>Improved Legerdemain (Su):</span>
A perfect wight can perform the following class skills at a range of 30
feet: Disable Device, Open Lock, Pick Pocket, and Search. If desired,
the perfect wight can take 10 on the check. Any object manipulated
during the skill check must weigh 100 pounds or less. Alternatively,
the perfect wight can use improved legerdemain to make one melee sneak
attack against any creature within 30 feet. The perfect wight executes
the sneak attack (or death attack, if applicable) as if attacking from
a flanking position. If the attack is successful, the victim is dealt
the appropriate sneak attack damage despite the fact that the perfect
wight and his or her weapon do not physically cross the intervening
distance. A perfect wight can use improved legerdemain once per day at
2nd level, plus one additional time per day every five levels
thereafter. </p>
    <a id="perfect-wight-incorporeal"></a><p><span style={{
        "fontWeight": "bold"
      }}>Incorporeal (Su):</span> At
3rd level, the perfect wight can become incorporeal once per day, plus
one additional time per day every five levels thereafter. A perfect
wight can remain incorporeal for a number of rounds equal to 20 + his
or her perfect wight level. As an incorporeal creature, the perfect
wight can be harmed only by other incorporeal creatures, +1 or better
magic weapons, and spells, spell-like abilities, and supernatural
abilities. He or she is immune to all nonmagical attack forms. Even
when hit by spells or magic weapons, the perfect wight has a 50% chance
to ignore any damage from a corporeal source (except for force effects
or attacks made with ghost touch weapons). An incorporeal perfect wight
has no natural armor but has a deflection bonus equal to his or her
Charisma modifier (always at least +1, even if his or her Charisma
score does not normally provide a bonus). An incorporeal perfect wight
can pass through solid objects at will, but not force effects. His or
her attack ignores natural armor, armor, and shields, although
deflection bonuses and force effects work normally against it. An
incorporeal perfect wight moves silently and cannot be heard with
Listen checks if he or she doesn&#8217;t wish to be. While incorporeal, the
perfect wight has no Strength score, so his or her Dexterity modifier
applies to both melee and ranged attacks. </p>
    <a id="perfect-wight-shadow-form"></a><p><span style={{
        "fontWeight": "bold"
      }}>Shadow Form (Su):</span> At
4th level, the perfect wight can take shadow form once per day, plus
one additional time per day every five levels thereafter. The perfect
wight&#8217;s shadow form lasts 1 minute per level of the prestige class.
While in shadow form the perfect wight is incorporeal (see above), is
immune to critical hits, and can fly at a speed of 100 feet (good). The
perfect wight can also use the substance of his or her own shadow to
enhance his or her effective level on any attack roll, check, or saving
throw. Drawing power from his or her own shadow form deals the perfect
wight 7 points of damage for each +1 bonus on a single roll or +1
effective level for any other single use. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Bonus Feats:</span> The
perfect wight gets a bonus feat at 5th level and an additional bonus
feat every five levels thereafter. These bonus feats must be selected
from the following list: Blinding Speed, Combat Archery, Dexterous
Fortitude, Dexterous Will, Epic Dodge, Epic Reputation, Epic Skill
Focus, Epic Speed, Epic Trapfinding, Improved Combat Reflexes, Improved
Sneak Attack, Legendary Climber, Lingering Damage, Self-Concealment,
Sneak Attack of Opportunity, Spellcasting Harrier, Superior Initiative,
Uncanny Accuracy. </p>
    <p> </p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      